import React, { useEffect, useLayoutEffect, useRef } from "react";
import "../style/LandingPage.css"; // Import CSS for styling
import Lenis from "@studio-freight/lenis";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let imageSources = [];
for (let x = 1; x <= 80; x++) {
  if (x !== 17 && x !== 21 && x !== 45) {
    imageSources.push(`/${x}-min.jpg`);
  }
}

const LandingPage = () => {
  const [heroImg, setHeroImg] = React.useState("/placeholder.svg");
  function generateHeroImg() {
    let randomSrc =
      imageSources[Math.floor(Math.random() * imageSources.length)];
    setHeroImg((prev) => randomSrc);
  }
  useEffect(() => {
    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    generateHeroImg();
    requestAnimationFrame(raf);
  }, []);

  const containerRef = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      //gsap code here in the future
    }, containerRef);

    return () => ctx.revert();
  }, []);
  return (
    <main ref={containerRef}>
      <nav>
        <a href="/">antonmika</a>
      </nav>
      <section>
        <div className="hero">
          {<img src={heroImg} alt="hero" loading="lazy" />}
          {/* <img src="/68-min.jpg" alt="hero" /> */}
          <div>
            <h1>Mika</h1>
            <p>
              Sofiya's little black dog that she desperately wanted to have an
              image gallery for. Enjoy this collection of 80 photos. Shoutout to
              California for the views. Also I guess thanks to Alex for the site and Dasha for the photos. Amen.
            </p>
          </div>
        </div>
        <div className="gallery">
          <ul className="">
            {imageSources.map((source, i) => (
              <li key={i}>
                <img src={source} alt={`Image ${i + 1}`} />
              </li>
            ))}
          </ul>
        </div>
      </section>
    </main>
  );
};

export default LandingPage;
